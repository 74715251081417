/* ======= VARIABLES ======= */

$fa-font-path:	"fonts";

$enable-rounded: false;
$primary: #d60f3c;
$light: #F1F1F2;
$font-family-sans-serif: 'Open Sans', sans-serif;
$headings-font-family: 'Roboto', sans-serif;
$headings-font-weight: 700;
$font-size-base: 1rem;
$h1-font-size: $font-size-base * 2;
$h2-font-size: $font-size-base * 1.6;
$h3-font-size: $font-size-base * 1.3;
$h4-font-size: $font-size-base * 1.25;
$h5-font-size: $font-size-base * 1.15;
$h6-font-size: $font-size-base;
$display1-size: 2.5rem;
$display2-size: 2rem;
$display1-weight: 700;
$btn-border-width: 2px;
$btn-font-family: $headings-font-family;
$btn-font-weight: 700;
$card-border-color: transparent;

/* ======= IMPORT BOOTSTRAP, FONTS & OTHERS ======= */

/* lato-regular - latin */
@font-face {
	font-display: swap; /* Check https://developer.mozilla.org/en-US/docs/Web/CSS/@font-face/font-display for other options. */
	font-family: 'Lato';
	font-style: normal;
	font-weight: 400;
	src: url('./fonts/lato-v24-latin-regular.woff2') format('woff2'), /* Chrome 36+, Opera 23+, Firefox 39+, Safari 12+, iOS 10+ */
	url('./fonts/lato-v24-latin-regular.ttf') format('truetype'); /* Chrome 4+, Firefox 3.5+, IE 9+, Safari 3.1+, iOS 4.2+, Android Browser 2.2+ */
}
/* lato-italic - latin */
@font-face {
	font-display: swap; /* Check https://developer.mozilla.org/en-US/docs/Web/CSS/@font-face/font-display for other options. */
	font-family: 'Lato';
	font-style: italic;
	font-weight: 400;
	src: url('./fonts/lato-v24-latin-italic.woff2') format('woff2'), /* Chrome 36+, Opera 23+, Firefox 39+, Safari 12+, iOS 10+ */
	url('./fonts/lato-v24-latin-italic.ttf') format('truetype'); /* Chrome 4+, Firefox 3.5+, IE 9+, Safari 3.1+, iOS 4.2+, Android Browser 2.2+ */
}
/* lato-700 - latin */
@font-face {
	font-display: swap; /* Check https://developer.mozilla.org/en-US/docs/Web/CSS/@font-face/font-display for other options. */
	font-family: 'Lato';
	font-style: normal;
	font-weight: 700;
	src: url('./fonts/lato-v24-latin-700.woff2') format('woff2'), /* Chrome 36+, Opera 23+, Firefox 39+, Safari 12+, iOS 10+ */
	url('./fonts/lato-v24-latin-700.ttf') format('truetype'); /* Chrome 4+, Firefox 3.5+, IE 9+, Safari 3.1+, iOS 4.2+, Android Browser 2.2+ */
}
/* lato-700italic - latin */
@font-face {
	font-display: swap; /* Check https://developer.mozilla.org/en-US/docs/Web/CSS/@font-face/font-display for other options. */
	font-family: 'Lato';
	font-style: italic;
	font-weight: 700;
	src: url('./fonts/lato-v24-latin-700italic.woff2') format('woff2'), /* Chrome 36+, Opera 23+, Firefox 39+, Safari 12+, iOS 10+ */
	url('./fonts/lato-v24-latin-700italic.ttf') format('truetype'); /* Chrome 4+, Firefox 3.5+, IE 9+, Safari 3.1+, iOS 4.2+, Android Browser 2.2+ */
}

/* open-sans-regular - latin */
@font-face {
	font-display: swap; /* Check https://developer.mozilla.org/en-US/docs/Web/CSS/@font-face/font-display for other options. */
	font-family: 'Open Sans';
	font-style: normal;
	font-weight: 400;
	src: url('./fonts/open-sans-v35-latin-regular.woff2') format('woff2'), /* Chrome 36+, Opera 23+, Firefox 39+, Safari 12+, iOS 10+ */
	url('./fonts/open-sans-v35-latin-regular.ttf') format('truetype'); /* Chrome 4+, Firefox 3.5+, IE 9+, Safari 3.1+, iOS 4.2+, Android Browser 2.2+ */
}
/* open-sans-italic - latin */
@font-face {
	font-display: swap; /* Check https://developer.mozilla.org/en-US/docs/Web/CSS/@font-face/font-display for other options. */
	font-family: 'Open Sans';
	font-style: italic;
	font-weight: 400;
	src: url('./fonts/open-sans-v35-latin-italic.woff2') format('woff2'), /* Chrome 36+, Opera 23+, Firefox 39+, Safari 12+, iOS 10+ */
	url('./fonts/open-sans-v35-latin-italic.ttf') format('truetype'); /* Chrome 4+, Firefox 3.5+, IE 9+, Safari 3.1+, iOS 4.2+, Android Browser 2.2+ */
}
/* open-sans-700 - latin */
@font-face {
	font-display: swap; /* Check https://developer.mozilla.org/en-US/docs/Web/CSS/@font-face/font-display for other options. */
	font-family: 'Open Sans';
	font-style: normal;
	font-weight: 700;
	src: url('./fonts/open-sans-v35-latin-700.woff2') format('woff2'), /* Chrome 36+, Opera 23+, Firefox 39+, Safari 12+, iOS 10+ */
	url('./fonts/open-sans-v35-latin-700.ttf') format('truetype'); /* Chrome 4+, Firefox 3.5+, IE 9+, Safari 3.1+, iOS 4.2+, Android Browser 2.2+ */
}
/* open-sans-700italic - latin */
@font-face {
	font-display: swap; /* Check https://developer.mozilla.org/en-US/docs/Web/CSS/@font-face/font-display for other options. */
	font-family: 'Open Sans';
	font-style: italic;
	font-weight: 700;
	src: url('./fonts/open-sans-v35-latin-700italic.woff2') format('woff2'), /* Chrome 36+, Opera 23+, Firefox 39+, Safari 12+, iOS 10+ */
	url('./fonts/open-sans-v35-latin-700italic.ttf') format('truetype'); /* Chrome 4+, Firefox 3.5+, IE 9+, Safari 3.1+, iOS 4.2+, Android Browser 2.2+ */
}

@import '../../../node_modules/bootstrap/scss/functions';
@import '../../../node_modules/bootstrap/scss/variables';
@import '../../../node_modules/bootstrap/scss/bootstrap';

@import '../../../node_modules/@fortawesome/fontawesome-free/scss/variables';
@import '../../../node_modules/@fortawesome/fontawesome-free/scss/fontawesome';
@import '../../../node_modules/@fortawesome/fontawesome-free/scss/regular';
@import '../../../node_modules/@fortawesome/fontawesome-free/scss/solid';
@import '../../../node_modules/@fortawesome/fontawesome-free/scss/brands';


/* ======= LAYOUT ======= */



.bg-cover {
	background-repeat: no-repeat;
	background-position: 50% 25%;
	background-size: cover;
}

#intro-sale {
	> .d-lg-flex {
		.flex-item {
			width: 100%;
			background: 0 none;
		}
		.flex-img {
			width: 100%;
			background: url(img/img-intro.jpg) no-repeat 80% 50%;
			min-height: 22rem;
			background-size: cover;
			@include media-breakpoint-up(sm) {
				min-height: 25rem;
			}
			@include media-breakpoint-up(md) {
				min-height: 28rem;
			}
			@include media-breakpoint-up(lg) {
				min-height: 43rem;
			}
		}
	}
}


/* ======= TYPO ======= */

@include media-breakpoint-up(md) {
	html {
		font-size: 1.125rem;
	}
}

h4,
.h4 {
	color: $secondary;
}

.btn {
	text-transform: uppercase;
}

.card-title {
	color: $primary;
}

.fa, .fas {
	color: $primary;
}


/* ======= FORM ======= */

$form_bg: transparent;

$form_div_width: 100%;

$form_label_width: 35%;
$form_label_color: #6C757D;
$form_label_fontsize: 100%;

$form_input_color: #666;
$form_input_bg: #fff;
$form_input_border: 1px solid transparent;
$form_input_width: 60%;
$form_input_padding: 1% 2%;
$form_input_fontsize: 100%;

$form_input_color_focus: #333;
$form_input_border_focus: 1px solid #ddd;
$form_input_bg_focus: #fff;

$form_privacy_fontsize: 80%; 

$form_invia_bg: $primary;
$form_invia_bg_hover: $dark;
$form_invia_color: #fff;
$form_invia_width: auto;
$form_invia_height: auto;
$form_invia_border: 1px solid #ffffff;
$form_invia_fontsize: 140%;

$form_error_color: red;


#nomesia_form {
	padding: 20px;
	margin: 10px 0;
	clear: both;
	background: $form_bg;
	fieldset {
		border: none;
		margin: 0;
		padding: 0;
	}
	input[type="hidden"] {
		display: none !important;
	}
	div {
		padding-bottom: 5px;
		margin: 5px 0px 5px 0;
		float: left;
		width: $form_div_width;
		&#submit_container{
			float: none;
			clear: both;
			margin: 0 auto;
		}
		select {
			width: $form_input_width;
			*width:130px;
		}
	}
	label {
		margin-top: 10px;
		float: left;
		color: $form_label_color;
		font-size: $form_label_fontsize; 
		display: block;
		width: $form_label_width;
		*width: 100px; /* IE7 vuole necessariamente un valore in pixel */
		&.error {
			display: block;
			width: 90%!important;
			font-size: 80% !important;
			padding: 2px 5px;
			color: $form_error_color;
			background: none;
			border: none;
		}
	}
	textarea, input, select {
		color: $form_input_color;
		background: $form_input_bg;
		border: $form_input_border;
		font-size: $form_input_fontsize;
		padding: $form_input_padding;
		width: $form_input_width;
		*width: 100px;
		float: left;
		display:inline-block;
		-webkit-transition: border linear .2s, box-shadow linear .2s;
		-moz-transition: border linear .2s, box-shadow linear .2s;
		-o-transition: border linear .2s, box-shadow linear .2s;
		transition: border linear .2s, box-shadow linear .2s;
		&:focus	{
			color: $form_input_color_focus;
			border: $form_input_border_focus;
			background: $form_input_bg_focus;
			outline: 0;
			-webkit-box-shadow: inset 0 1px 1px rgba(0,0,0,.075), 1px 1px 4px rgba(0,0,0,.4);
			-moz-box-shadow: inset 0 1px 1px rgba(0,0,0,.075), 1px 1px 4px rgba(0,0,0,.4);
			box-shadow: inset 0 1px 1px rgba(0,0,0,.075), 1px 1px 4px rgba(0,0,0,.4);
		}
	}
	textarea {
		height: 80px;
	}
	#privacy_container, #marketing_container {
		border: none;
		float: left;
		padding: 0 0 5px;
		label{
			float: left;
			font-weight: normal;
			display: block;
			width: 80%;
			font-size: $form_privacy_fontsize;
			line-height: 1.2em;
			clear: none;
		}
		input {
			margin: 5px 20px 0 0;
			padding: 0;
			background: none;
			border: none;
			width: 15px;
			float: left;
		}
	}
	#more_info_container {
		label {
			width: 100%;
			font-size: 110%;
		}
	}
	.button_form {
		background: $form_invia_bg;
		color: $form_invia_color;
		font-weight: bold;
		text-transform: uppercase;
		border: $form_invia_border;
		width: $form_invia_width;
		display: block;
		margin: 15px auto;
		padding: 8px 20px;
		height: $form_invia_height;
		font-size: $form_invia_fontsize;
		float: none;
		cursor: pointer;
		&:hover, &:focus {
			background: $form_invia_bg_hover;
			color: $form_invia_color;
		}
	}
	.checked {
		display: none!important;
	}
	.field_status{
		margin: 0;
		padding: 0;
		&.error{
			display: block;
		}
	}
	span.ast {
		color: red;
	}
}

@media \0screen {
	#nomesia_form textarea:focus, #nomesia_form input:focus, #nomesia_form select:focus{outline: thin dotted;}
}

@include media-breakpoint-down(xs) {

	#nomesia_form label {width: 100% !important;}
	#nomesia_form input {width: 100% !important;}
	#nomesia_form select {width: 100% !important;}
	#nomesia_form textarea {width: 100% !important;}
	#nomesia_form #privacy_container input, #nomesia_form #marketing_container input {width: 5% !important; margin: 0 4% 0 0;}
	#nomesia_form #privacy_container label, #nomesia_form #marketing_container label{width: 91% !important; padding: 0;}
	#nomesia_form .button_form {white-space: normal;}
}


/* ======= BACK TO TOP ======= */

#top {
	position: fixed;
	width: 40px;
	height: 40px;
	border-radius: 0;
	background: $primary;
	bottom: 20px;
	right: 20px;
	cursor: pointer;
	#top-arrow {
		-o-transform: rotate(45deg);
		-moz-transform: rotate(45deg);
		-webkit-transform: rotate(45deg);
		transform: rotate(45deg);
		border-top: 5px solid #fff;
		border-left: 5px solid #fff;
		border-bottom: none;
		border-right: none;
		width: 10px;
		height: 10px;
		margin: 15px auto;
	}
}



